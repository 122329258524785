<template>
  <el-dialog
    title="Tva"
    :visible.sync="showPopup"
    append-to-body
    id="popup_dialog"
    width="90%"
    class="my-dialog-class"
  >
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
    >
      <el-row :gutter="15">
        <el-col :md="8">
          <el-form-item prop="Procent" label="Procent">
            <el-input-number
              class="full-width"
              v-model="selectedObject.Procent"
            />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item>
            <el-switch
              class="dezactivat"
              v-model="selectedObject.Dezactivat"
              active-value="0"
              inactive-value="1"
              active-text="Activ"
              inactive-text="Dezactivat"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
            <!-- <el-checkbox class="full-width" v-model="selectedObject.Dezactivat" true-label="1" false-label="0">
              Dezactivat
            </el-checkbox> -->
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showPopup = false"> Renunta </el-button>
      <el-button type="primary" @click="save"> Salveaza </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";

export default {
  name: "Tva_dialog",
  extends: BasePage,
  components: {},
  data() {
    return {
      baseUrl: "",
      showPopup: false,
      mode: "add",
      selectedObject: {
        Id: "",
        Procent: "",
      },
      Info: {
        // clienti: [],
      },
      rules: {
        Procent: [{ required: true, message: "Campul este obligatoriu" }],
      },
    };
  },
  methods: {
    show_me: async function (id) {
      this.selectedObject = {
        Id: "",
        Procent: "",
      };
      this.showPopup = true;
      if (id == null) {
        this.mode = "add";
      } else {
        this.mode = "edit";
        var result = await this.post("tva/get_info_item_dialog", {
          id: id,
        });
        this.selectedObject = result.Item;
        this.handleDezactivat("get");
      }
    },

    async get_info() {},

    handleDezactivat: async function (mode) {
      if (mode === "save") {
        if (this.selectedObject.Dezactivat) {
          this.selectedObject.Dezactivat = "1";
        } else {
          this.selectedObject.Dezactivat = "0";
        }
      } else if (mode === "get") {
        if (this.selectedObject.Dezactivat == "1") {
          this.selectedObject.Dezactivat = true;
        } else {
          this.selectedObject.Dezactivat = false;
        }
      }
    },

    save: async function () {
      this.$refs["my-form"].validate(async (valid) => {
        this.handleDezactivat("save");
        if (valid) {
          await this.post("tva/save", {
            mode: this.mode,
            object: this.selectedObject,
          });
          this.showPopup = false;
          this.$emit("save");
        }
      });
    },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}
</style>